const glyphs = ['𓅰', '𓃰', '𓀌', '𓋍', '𓆉', '𓇵', '𓀒', '𓃲']
const rdmGlyph = () => glyphs[Math.floor(Math.random() * glyphs.length)];

const rdm1 = rdmGlyph()
const rdm2 = rdmGlyph()

const styleConfigurations = [
    {
        getStyle: (cell) => {
            switch (cell % 10) {
                case 0:
                    return { symbol: '𓍴', fontColor: '#fff', bgColor: '#151515' };
                case 1:
                    return { symbol: rdm1, fontColor: '#fff', bgColor: '#3d3d3d' };
                case 2:
                    return { symbol: rdm2, fontColor: '#fff', bgColor: '#000' };
                case 9:
                    return { symbol: '·', fontColor: '#FFF', bgColor: '#000' };
                default:
                    return { symbol: ' ', fontColor: '#000', bgColor: '#000' };
            }
        },
    },
 {
        getStyle: (cell) => {
            switch (cell % 10) {
                case 0:
                    return { symbol: '░', fontColor: '#fff', bgColor: '#151515' };
                case 1:
                    return { symbol: '▓', fontColor: '#fff', bgColor: '#3d3d3d' };
                case 2:
                    return { symbol: '▒', fontColor: '#fff', bgColor: '#000' };
                case 9:
                    return { symbol: '·', fontColor: '#FFF', bgColor: '#000' };
                default:
                    return { symbol: ' ', fontColor: '#000', bgColor: '#000' };
            }
        },
    },   
    {
        name: 'point',
        getStyle: (cell) => {
            switch (cell % 10) {
                case 0:
                    return { symbol: '·', fontColor: '#fff', bgColor: '#151515' };
                case 1:
                    return { symbol: '*', fontColor: '#fff', bgColor: '#3d3d3d' };
                case 2:
                    return { symbol: '⬤', fontColor: '#fff', bgColor: '#000' };
                case 9:
                    return { symbol: '·', fontColor: '#FFF', bgColor: '#000' };
                default:
                    return { symbol: ' ', fontColor: '#000', bgColor: '#000' };
            }
        },
    },
    {
        getStyle: (cell) => {
            switch (cell % 10) {
                case 0:
                    return { symbol: 'Y', fontColor: '#fff', bgColor: '#151515' };
                case 1:
                    return { symbol: '*', fontColor: '#fff', bgColor: '#3d3d3d' };
                case 2:
                    return { symbol: '<', fontColor: '#fff', bgColor: '#000' };
                case 9:
                    return { symbol: '---', fontColor: '#FFF', bgColor: '#000' };
                default:
                    return { symbol: ' ', fontColor: '#000', bgColor: '#000' };
            }
        },
    }
];

export default styleConfigurations;