import { voronoi } from 'd3-voronoi';
import { createNoise3D } from 'simplex-noise';
import { lerpColor, rgbToHex, hexToRgb } from '../utils/Utils.js';

const noise = createNoise3D();
let noiseZ = 10;

export const map = {
  name: 'map',
  generate: (width, height, numStates) => {
    const squareSize = Math.min(width, height);

    let world = Array.from({ length: height }, () =>
      Array.from({ length: width }, () => [0, 0, [{ bgColor: '#3c3834' }]])
    );

    const sites = [];
    for (let i = 0; i < 54; i++) {
      sites.push({ x: Math.random() * squareSize, y: Math.random() * squareSize });
    }

    const voronoiDiagram = voronoi().extent([[0, 0], [squareSize, squareSize]]).x(d => d.x).y(d => d.y);
    const diagram = voronoiDiagram(sites);

    const edgeSites = new Set();

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const site = diagram.find(x, y);
        const colorIndex = sites.indexOf(site.data);
        if (x === 0 || x === squareSize - 1 || y === 0 || y === squareSize - 1) {
          edgeSites.add(colorIndex);
        }
      }
    }

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const site = diagram.find(x, y);
        const colorIndex = sites.indexOf(site.data);
        const color = edgeSites.has(colorIndex) ? '#3c3834' : `hsl(${colorIndex * 360 / sites.length}, 100%, 50%)`;
        if (x < squareSize && y < squareSize) {
          world[y][x][2].bgColor = color;
        }
      }
    }

    world = postProcessBiomes(world, width, height, numStates);
    world = postProcessDesert(world, width, height, numStates)
    world = postProcessElevation(world, width, height, numStates)

    return world;
  }
};

function postProcessDesert(world, width, height, numStates) {
  noiseZ += 0.005;
  const squareSize = Math.min(width, height);

  const noiseScaleX = squareSize / 4;
  const noiseScaleY = squareSize / 4;

  for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
          const value = noise(x / noiseScaleX, y / noiseScaleY, noiseZ);
          const normalizedValue = (value + 1) / 2;
          const roundedNormalizedValue = Math.round(normalizedValue * 1000) / 1000;

          if (x < squareSize && y < squareSize) {
              if (roundedNormalizedValue < 0.33) {
                  world[y][x][2].bgColor = '#3c3834';
              } else if (roundedNormalizedValue >= 0.33 && roundedNormalizedValue <= 0.45) {
                  let currentColor = hexToRgb(world[y][x][2].bgColor);
                  let targetColor = [69, 69, 69];
                  let t = (roundedNormalizedValue - 0.33) / (0.45 - 0.33);
                  let lerpedColor = lerpColor(targetColor, currentColor, t);
                  if(lerpedColor[2] !== undefined) {
                    world[y][x][2].bgColor = rgbToHex(lerpedColor[0], lerpedColor[1], lerpedColor[2]);
                  }
              }
          }
      }
  }
  return world;
}

function postProcessElevation(world, width, height, numStates) {
  const squareSize = Math.min(width, height);

  noiseZ += 0.005;

  const noiseScaleX = squareSize / 4;
  const noiseScaleY = squareSize / 4;

  for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        if (world[y][x][2].bgColor !== '#3c3834') {

          if (x < squareSize && y < squareSize && world[y][x][2].bgColor !== '#000') {
              const value = noise(x / noiseScaleX, y / noiseScaleY, noiseZ);
              const elevation = (value + 1) / 2 * 9;  // Map [-1, 1] -> [0, 9]

              let currentColor = hexToRgb(world[y][x][2].bgColor);
              let targetColor = [69,69,69];
              let t = elevation / 9;

              let lerpedColor = lerpColor(currentColor, targetColor, t);
              world[y][x][2].bgColor = rgbToHex(lerpedColor[0], lerpedColor[1], lerpedColor[2]);
          }
        }
      }
  }

  return world;
}



function postProcessBiomes(world, width, height, numStates) {
  const squareSize = Math.min(width, height);
  const biomeSites = [];
  for (let i = 0; i < 11; i++) {
      biomeSites.push({ x: Math.random() * squareSize, y: Math.random() * squareSize });
  }

  const biomeVoronoi = voronoi().extent([[0, 0], [squareSize, squareSize]]).x(d => d.x).y(d => d.y);
  const biomeDiagram = biomeVoronoi(biomeSites);

  const biomeColors = ['#101010', '#202020', '#303030', '#404040', '#505050'];
  const colorAssignment = new Array(biomeSites.length);

  biomeColors.forEach((color, index) => {
      colorAssignment[index] = color;
  });

  for (let i = biomeColors.length; i < biomeSites.length; i++) {
      colorAssignment[i] = biomeColors[Math.floor(Math.random() * biomeColors.length)];
  }

  for (let i = colorAssignment.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [colorAssignment[i], colorAssignment[j]] = [colorAssignment[j], colorAssignment[i]];
  }

  for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
          if (x < squareSize && y < squareSize && world[y][x][2].bgColor !== '#3c3834') {
              const site = biomeDiagram.find(x, y);
              const biomeIndex = biomeSites.indexOf(site.data);
              world[y][x][2].bgColor = colorAssignment[biomeIndex];
          }

          world[y][x][2].symbol = ' '

      }
  }

  return world;
}

