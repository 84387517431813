import React, { useEffect } from 'react';
import { useDisplay } from '../../context/DisplayContext';
import drawHeader from './functions/drawHeader'
import drawTerminal from './functions/drawTerminal'

const Ui = () => {
  const { display, dimensions, setContainerCoords, consoleTabOpen, consoleMessages, gen } = useDisplay();

  useEffect(() => {
    if (display && dimensions.widthInTiles) {

      // Bad hacking to rerender the canvas and show tiles correctly
      window.dispatchEvent(new Event('resize'));

      drawHeader(display, dimensions, gen);

      const containerCoords = drawTerminal(display, dimensions, consoleTabOpen);
      setContainerCoords(containerCoords)
        
    }
  }, [display, dimensions.widthInTiles, dimensions.heightInTiles, consoleTabOpen]);

  return null
};

export default Ui;
