import React, { useContext, useEffect } from 'react';
import { useDisplay } from '../../../context/DisplayContext';

import {clearContainer} from '../utils/Utils';

const Content = () => {
    const { display, containerCoords, activeTab, setWalletTabOpen, walletTabOpen, setIsMore } = useDisplay();
    const blank = '%b{#000}%c{#000}------------------------------'

    useEffect(() => {
        if (!display || !containerCoords || containerCoords.length === 0) return;

        // setWalletTabOpen(false)
        clearContainer(display, containerCoords[0], 4);

        const walletTabHeight = walletTabOpen ? 10 : 4;
        const contentArea = containerCoords[0];
        const totalContentAreaHeight = contentArea.endY - contentArea.startY;
        const textAreaHeight = totalContentAreaHeight - walletTabHeight;

        const startX = contentArea.startX + 4;
        const startY = contentArea.startY + 1;

        const sectionLineCounts = [1, 1, 8, 1, 1, 1, 17];
        setIsMore(sectionLineCounts[activeTab - 1] > textAreaHeight ? true : false);

        const messages = [
            "",
            "KHAMSIN",
            "MEHEN",
            "NETERU",
            "AARU",
            "SEKHMET'S WRATH",
            "THE COLLECTION"
        ];
        
        display.drawText(startX, startY, `%c{#FFF}${messages[activeTab]}`, '#000');

        if (activeTab === 1) {
            display.drawText(startX, startY + 2, `Named after the fierce desert winds of Egypt, Sector Khamsin is a realm where the sands are alive, whispering ancient secrets and concealing lost technologies.`, 29);
            display.drawText(startX, startY + 9, `The sands move with a purpose, guided by unseen hands of forgotten gods, reshaping the landscape with each new dawn.`, 29);
        }
         

        if (activeTab === 2) {
            display.drawText(startX, startY + 2, `Named after the ancient serpent god who encircles the sun, Sector Mehen is a labyrinth of colossal structures that rise like monolithic sentinels.`, 29);
            display.drawText(startX, startY + 9, `These structures are living entities, evolving and expanding, sometimes succumbing to the relentless advance of the desert sands, blurring the line between order and chaos.`, 29);
        }

        if (activeTab === 3) {
            display.drawText(startX, startY + 2, `Sector Neteru is a region of immense structures that echo the sacred geometries of the gods.`, 29);
            display.drawText(startX, startY + 7, `This sector is a coveted territory where the spice crystallizes in the shadows of towering structures, attracting adventurers and traders in search of fortune and mystical power.`, 29);
        }

        if (activeTab === 4) {
            display.drawText(startX, startY + 2, `Named after the Egyptian paradise, Aaru represents the idealized Field of Reeds, a land of eternal sustenance and prosperity.`, 29);
            display.drawText(startX, startY + 8, `In this sector, the grid-like fields are interspersed with canals and nutrient pathways that ensure the growth of the rare and precious spice.`, 29);
            display.drawText(startX, startY + 14, `This spice farm is a testament to the possibility of abundance in the heart of the desert, providing life-sustaining resources to the entire world.`, 29);
        }

        if (activeTab === 5) {
            display.drawText(startX, startY + 2, `The Sekhmet's Wrath is a place of eternal fire and fury. Named after the lioness goddess of war and destruction, this area is a landscape of perpetual flames.`, 29);
            display.drawText(startX, startY + 10, `The ground pulses with the anger of the gods, and the air is thick with the heat of constant infernos.`, 29);
        }

        if (activeTab === 6) {
            display.drawText(startX, startY + 2, `Press ESCAPE to close`, 29);
        }

    }, [display, containerCoords, activeTab, walletTabOpen]);

    return null;
};

export default Content;
