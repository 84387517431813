import { noise3d } from './g/Khamsin'
import { ruleBased } from './g/Mehen'
import { voronoy } from './g/Neteru'
import { mondrian } from './g/Aaru'
import { map } from './WorldMap'

const generationConfigurations = [
    noise3d,
    ruleBased,
    voronoy,
    mondrian,
    {
        name: 'Sekhmet-s Wrath',
        generate: (width, height, numStates) =>
            Array.from({ length: height }, () =>
                Array.from({ length: width }, () => [2, 9, []])
            ),
    }
];

export default generationConfigurations;