import React, { useEffect, useState, useCallback } from 'react';
import { useDisplay } from '../context/DisplayContext';
import styleConfurations from './conf/Style';
import automataConfurations from './conf/Automata';
import generationConfurations from './conf/Generation';
import { getNeighbors, lerpColor, rgbToHex, hexToRgb } from './utils/Utils.js';
import { Pattern, createRandomPatterns } from './creatures/Pattern';
import { createNoise3D } from 'simplex-noise';
import { transition } from './UX/Transition';
import useKeyPressHandlers from './Keypress';
import { postProcessFontColor, postProcessAnimation, processHeightMap } from './UX/PostProcess';

const noise = createNoise3D();
let noiseZ = 10;

const World = () => {
  const { display, dimensions, scheduler, engine, fps, gen, setGen } = useDisplay();
  const [world, setWorld] = useState([]);
  const [generationConf, setGenerationConf] = useState(null);
  const [automataConf, setAutomataConf] = useState(null);
  const [styleConf, setStyleConf] = useState(null);
  const [showUI, setShowUI] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [patterns, setPatterns] = useState([]);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [heightMap, setHeightMap] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [transitioning, setTransitioning] = useState(false);

  const styleToAutomata = {
    'Khamsin': 'plasma',
    'Mehen': 'cyclic',
    'Neteru': 'none',
    'Aaru': 'none',
    'Sekhmet-s Wrath': 'fire'
  };

  const selectRandomStyle = () => {
    const styleChoices = styleConfurations;
    return styleChoices[Math.floor(Math.random() * styleChoices.length)];
  };

  const clearScheduler = useCallback(() => {
    if (scheduler) {
      scheduler.clear();
      scheduler._repeat.length = 0;
    }
  }, [scheduler]);

  const addWorldActToScheduler = useCallback((newAutomataConf) => {
    if (scheduler) {
      console.log(newAutomataConf)
      scheduler.add({
        act: () => worldAct(newAutomataConf)
      }, true);
    }
  }, [scheduler]);

  const regen = useCallback((generationName) => {
    if (!dimensions || !engine || !scheduler || transitioning) return;

    setTransitioning(true);

    engine.lock();
    clearScheduler();

    patterns.forEach(pattern => pattern.destroy());
    setPatterns([]);

    const selectedGeneration = generationConfurations.find(g => g.name === generationName);
    if (!selectedGeneration) {
      setTransitioning(false);
      engine.unlock();
      return;
    }

    const newWorld = selectedGeneration.generate(dimensions.widthInTiles, dimensions.heightInTiles, 16);

    setIsRunning(false);
    setGenerationConf(selectedGeneration);

    const automataName = styleToAutomata[generationName];
    const selectedAutomata = automataConfurations.find(a => a.name === automataName) || null;
    setAutomataConf(selectedAutomata);

    transition(newWorld, styleConf, display, () => {
      setWorld(newWorld);
      setIsRunning(true);
      setTransitioning(false);

      clearScheduler();
      addWorldActToScheduler(selectedAutomata);

      engine.unlock();
    });
  }, [dimensions, patterns, engine, scheduler, transitioning]);

  useEffect(() => {
    console.log(gen)
    setGen(generationConf)
  }, [generationConf]);

  useEffect(() => {
    if (!dimensions || !display || isInitialized) return;

    const queryParams = new URLSearchParams(window.location.search);
    const generationName = queryParams.get('g');
    let selectedGeneration = generationConfurations.find(g => g.name === generationName) || null;

    if (!selectedGeneration) {
      const allowedGenerations = generationConfurations.filter(g => [
        'Khamsin',
        'Mehen',
        'Neteru',
        'Aaru',
        'Sekhmet-s Wrath'
      ].includes(g.name));
      selectedGeneration = allowedGenerations[0]
      // selectedGeneration = allowedGenerations[Math.floor(Math.random() * allowedGenerations.length)];
    }

    setGenerationConf(selectedGeneration);
    const initialWorld = selectedGeneration.generate(dimensions.widthInTiles, dimensions.heightInTiles, 16);
    setWorld(initialWorld);
    setStyleConf(selectRandomStyle());

    const automataName = styleToAutomata[selectedGeneration.name];
    const automataConf = automataConfurations.find(a => a.name === automataName) || null;
    setAutomataConf(automataConf);

    if (['Aaru', 'Neteru'].includes(selectedGeneration.name)) {
      const newPatterns = createRandomPatterns(100, dimensions, setWorld);
      newPatterns.forEach(pattern => pattern.initialize());
      setPatterns(newPatterns);
    }

    setIsRunning(true);
    setIsInitialized(true);

    clearScheduler();
    addWorldActToScheduler(automataConf);

    return () => {
      clearScheduler();
    };
  }, [dimensions, display, isInitialized, clearScheduler, addWorldActToScheduler]);

  useEffect(() => {
    if (!automataConf || !isRunning || !scheduler) return;

    clearScheduler();
    addWorldActToScheduler(automataConf);

    return () => {
      clearScheduler();
    };
  }, [automataConf, isRunning, scheduler, clearScheduler, addWorldActToScheduler]);

  useEffect(() => {
    if (!dimensions || !display || !generationConf) return;

    patterns.forEach(pattern => {
      pattern.stop();
      pattern.destroy();
    });
    setPatterns([]);

    if (['Aaru', 'Neteru'].includes(generationConf.name) && isRunning) {
      const newPatterns = createRandomPatterns(100, dimensions, setWorld);
      newPatterns.forEach(pattern => pattern.initialize());
      setPatterns(newPatterns);
    }
  }, [generationConf, dimensions, display, isRunning]);

  const worldAct = (currentAutomataConf) => {
    if (!engine || transitioning) {
      return;
    }

    if (currentAutomataConf) {
      setWorld(currentWorld => currentAutomataConf.updateRule(currentWorld, getNeighbors, 16, dimensions.widthInTiles, dimensions.heightInTiles));
    }

    patterns.forEach(pattern => pattern.update());

    engine.lock();
    setTimeout(() => {
      engine.unlock();
    }, 1000 / fps);
  };

  useEffect(() => {
    if (!display || !Array.isArray(world) || world.length === 0) return;

    const drawCell = (x, y, cellData) => {
      if (!Array.isArray(cellData)) {
        console.error(`Invalid cellData format at (${x}, ${y}):`, cellData);
        return;
      }

      // Skip the last 63 cells in each row
      if (x >= dimensions.widthInTiles - 37) return;

      const [elevation, value, data] = cellData;
      const { symbol = ' ', fontColor = '#FFF', bgColor = '#000' } = data;

      let cellSymbol = data.symbol || styleConf.getStyle(value).symbol;
      let cellFontColor = fontColor;
      let cellBgColor = bgColor;

      if (generationConf.name === "Aaru") {
        if (data.isPattern && data.voronoi) {
          const [r, g, b] = hexToRgb(cellBgColor);
          const lerpedColor = lerpColor([r, g, b], [0, 0, 0], 0.1);
          cellFontColor = rgbToHex(lerpedColor[0], lerpedColor[1], lerpedColor[2]);
        }
      }

      if (generationConf.name === "Neteru") {
        cellFontColor = "#ffffff";
      }

      if (heightMap) {
        const processed = processHeightMap(x, y, data, elevation, generationConf, noise, noiseZ);
        cellFontColor = processed.fontColor;
        cellBgColor = processed.bgColor;
      }

      display.draw(x, y, cellSymbol, cellFontColor, cellBgColor);
    };

    world.forEach((row, y) => row.forEach((cellData, x) => drawCell(x, y, cellData)));

  }, [world, display, generationConf, styleConf, heightMap, showUI, isRunning, dimensions]);

  useKeyPressHandlers(
    setStyleConf,
    setIsRunning,
    postProcessFontColor,
    postProcessAnimation,
    heightMap,
    setHeightMap,
    showUI,
    setShowUI,
    regen,
    setWorld,
    dimensions,
    display,
    exportInProgress,
    setExportInProgress,
    engine,
    scheduler,
    automataConfurations,
    worldAct
  );

};

export default World;
