import {cyclic} from './a/Cyclic'
import {plasma} from './a/Plasma'
import {fire} from './a/Fire'

const automataConfigurations = [
  cyclic,
  plasma,
  {
    name: 'none',
    updateRule: (currentWorld, getNeighbors, numStates, width, height) => {
      return currentWorld;
    }
  },
  fire,
]

export default automataConfigurations;