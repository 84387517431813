class RuleSet {
    constructor(rules) {
        this.rules = rules;
    }

    getRuleForCell(state) {
        return this.rules.find(rule => rule[1] === state);
    }
}

class Pattern {
    constructor(dimensions, setWorld, ruleSet, x, y) {
        this.dimensions = dimensions;
        this.setWorld = setWorld;
        this.x = x !== undefined ? x : Math.floor(Math.random() * dimensions.widthInTiles);
        this.y = y !== undefined ? y : Math.floor(Math.random() * dimensions.heightInTiles);
        this.direction = Math.floor(Math.random() * 4);
        this.ruleSet = new RuleSet(ruleSet);
        this.timeout = null;
        this.stepsInCurrentDirection = 0;
        this.maxStepsBeforeTurn = 3;
        this.id = `${Date.now()}-${Math.random()}`;
        this.active = false;
    }

    initialize = () => {
        if (this.active) {
            return;
        }
        this.active = true;
        this.update();
    };

    destroy = () => {
        if (!this.active) {
            return;
        }
        clearTimeout(this.timeout);
        this.timeout = null;
        this.active = false;

        this.setWorld(currentWorld => {
            if (currentWorld[this.y] && currentWorld[this.y][this.x]) {
                currentWorld[this.y][this.x][2] = {
                    ...currentWorld[this.y][this.x][2],
                    symbol: ' ',
                    bgColor: '#000',
                    fontColor: '#FFF'
                };
            }
            return [...currentWorld];
        });
    };

    updateDirection = () => {
        if (this.stepsInCurrentDirection >= this.maxStepsBeforeTurn) {
            this.direction = (this.direction + (Math.floor(Math.random() * 2) * 2 + 1)) % 4;
            this.stepsInCurrentDirection = 0;
        } else {
            this.stepsInCurrentDirection++;
        }
    };

    calculateNewPosition = () => {
        const moves = [
            { x: 0, y: -1 },
            { x: 1, y: 0 },
            { x: 0, y: 1 },
            { x: -1, y: 0 }
        ];
        const move = moves[this.direction];
        const newX = (this.x + move.x + this.dimensions.widthInTiles) % this.dimensions.widthInTiles;
        const newY = (this.y + move.y + this.dimensions.heightInTiles) % this.dimensions.heightInTiles;
        return { x: newX, y: newY };
    };

    update = () => {
        if (!this.active) {
            return;
        }
        this.setWorld(currentWorld => {
            const currentState = currentWorld[this.y]?.[this.x]?.[1] || 0;

            // Get the rule for the current state
            const rule = this.ruleSet.getRuleForCell(currentState);
            if (rule) {
                this.updateDirection();
                const newPosition = this.calculateNewPosition();

                if (newPosition.y < currentWorld.length && newPosition.x < currentWorld[newPosition.y].length) {
                    currentWorld[newPosition.y][newPosition.x][1] = rule[3];
                    currentWorld[newPosition.y][newPosition.x][2].isPattern = true;
                    this.x = newPosition.x;
                    this.y = newPosition.y;
                }
            }

            return [...currentWorld];
        });

        this.timeout = setTimeout(this.update, 10);
    };

    stop = () => {
        if (!this.active) {
            return;
        }
        clearTimeout(this.timeout);
        this.timeout = null;
        this.active = false;
    };
}

// Standalone function to generate random rulesets
const generateRandomRuleset = (numColors, numStates) => {
    const rules = [];
    for (let color = 0; color < numColors; color++) {
        for (let state = 0; state < numStates; state++) {
            const resultingColor = Math.floor(Math.random() * numColors);
            const resultingState = Math.floor(Math.random() * numStates);
            const turnDirection = Math.floor(Math.random() * 4);
            rules.push([color, state, resultingColor, resultingState, turnDirection]);
        }
    }
    return rules;
};

// Standalone function to create random patterns
const createRandomPatterns = (numPatterns, dimensions, setWorld) => {
    return Array.from({ length: numPatterns }, () => new Pattern(dimensions, setWorld, generateRandomRuleset(5, 5)));
};

export { Pattern, RuleSet, createRandomPatterns, generateRandomRuleset };
