import { createNoise3D } from 'simplex-noise';

let noiseZ = 10;

export const plasma = {
    name: 'plasma',
    updateRule: (currentWorld, getNeighbors, numStates, width, height) => {
        noiseZ += 0.005;
        return currentWorld.map((row, y) => row.map((cell, x) => {
                const newValue = (cell[1] + 1) % numStates;
                return [cell[0], newValue, cell[2]];
        }));
    }
};
