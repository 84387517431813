import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import * as ROT from 'rot-js';

const DisplayContext = createContext();

export const useDisplay = () => useContext(DisplayContext);

export const DisplayProvider = ({ children }) => {
  const displayContainerRef = useRef(null);
  const [display, setDisplay] = useState(null);
  const [dimensions, setDimensions] = useState({ widthInTiles: 0, heightInTiles: 0 });
  const [scheduler, setScheduler] = useState(null);
  const [engine, setEngine] = useState(null);
  const [fps, setFps] = useState(6);

  const [gen, setGen] = useState(null);

  const [containerCoords, setContainerCoords] = useState([{}, {}, {}]);
  const [activeTab, setActiveTab] = useState(1);
  const [walletTabOpen, setWalletTabOpen] = useState(false);
  const [consoleMessages, setConsoleMessages] = useState([]);
  const [consoleTabOpen, setConsoleTabOpen] = useState(true);
  const [isMore, setIsMore] = useState(false);
  

  const addToConsole = (message, lines = 1) => {
    setConsoleMessages((prevMessages) => {
      const newMessages = [...prevMessages, message];
      return newMessages.slice(-9); // max 9 lines
    });
  };


  useEffect(() => {
    const TILE_WIDTH = 8;
    const TILE_HEIGHT = 8;

    let display;

    const initializeDisplay = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const widthInTiles = Math.floor(screenWidth / TILE_WIDTH);
      const heightInTiles = Math.floor(screenHeight / TILE_HEIGHT);

      setDimensions({ widthInTiles, heightInTiles });

      if (!display) {
        display = new ROT.Display({
          width: widthInTiles,
          height: heightInTiles,
          fontFamily: 'Johto',
          tileWidth: TILE_WIDTH,
          tileHeight: TILE_HEIGHT,
          fontSize: 11,
          fg: '#FFFFFF',
          bg: '#000000',
        });
        if (displayContainerRef.current) {
          displayContainerRef.current.appendChild(display.getContainer());
        }
      } else {
        display.setOptions({
          width: widthInTiles,
          height: heightInTiles,
        });
      }

      setDisplay(display)

      display.clear();
      for (let x = 0; x < widthInTiles; x++) {
        for (let y = 0; y < heightInTiles; y++) {
          display.draw(x, y, '', '#ccc', '#000');
        }
      }

    };

    initializeDisplay();

    const handleResize = () => {
      initializeDisplay();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (displayContainerRef.current && display.getContainer().parentNode) {
        displayContainerRef.current.removeChild(display.getContainer());
      }
    };
  }, []);

  useEffect(() => {
    if (display) {
      const newScheduler = new ROT.Scheduler.Simple();
      setScheduler(newScheduler);

      const newEngine = new ROT.Engine(newScheduler);
      setEngine(newEngine);
    }
  }, [display]);

  useEffect(() => {
    if (scheduler && engine) {
      engine.start();
    }
  }, [scheduler, engine]);

  return (
    <DisplayContext.Provider value={{ 
      display, dimensions, scheduler, engine, fps,

      gen, setGen,

      containerCoords, setContainerCoords,
      activeTab, setActiveTab,
      walletTabOpen, setWalletTabOpen,
      consoleTabOpen, setConsoleTabOpen,
      consoleMessages, addToConsole,
      isMore, setIsMore
      
      }}>
      <div ref={displayContainerRef}>{children}</div>
    </DisplayContext.Provider>
  );
};