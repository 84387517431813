import React, { useEffect } from 'react';
import { useDisplay } from '../../../context/DisplayContext';

import {clearContainer} from '../utils/Utils';

const Menu = () => {
    const { display, containerCoords, activeTab, consoleTabOpen } = useDisplay();

    useEffect(() => {
        if (!display || !containerCoords || containerCoords.length === 0) return;

        const startX = containerCoords[2].startX + 4;
        const startY = containerCoords[2].startY + 1;

        clearContainer(display, containerCoords[2])

        display.drawText(startX, startY, `%b{#3D3D3D}%c{#FFFFFF}::      %b{} %c{#87AAD0}PORTALS`);
        display.drawText(startX, startY + 2, `%c{#C69364}------------------`);

        const menuItems = [
            "KHAMSIN",
            "MEHEN",
            "NETERU",
            "AARU",
            "SEKHMET'S WRATH",
        ];

        menuItems.forEach((item, index) => {
            if (activeTab === index + 1) {
                display.drawText(startX, startY + 4 + index, `%b{#FFFFFF}%c{#000}<${index + 1}> ${item}`);
            } else {
                display.drawText(startX, startY + 4 + index, `%b{#141F60}%c{#FFFFFF}<${index + 1}>%b{} %c{#87AAD0}${item}`);
            }
        });

        display.drawText(startX, startY + 10, `%b{#141F60}%c{#FFFFFF}<6>%b{} %c{#87AAD0}GALLERY`);

    }, [display, containerCoords, activeTab, consoleTabOpen]);

    return null;
};

export default Menu;
