import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import { DisplayProvider } from './context/DisplayContext';

import World from './components/World';
import Ui from './components/UX/UI';
  import Content from './components/UX/e/Content.js';
  import WalletTab from './components/UX/e/WalletTab.js';
  import Console from './components/UX/e/Console.js';
  import Menu from './components/UX/e/Menu.js';

import Gallery from './components/Gallery';

// import MouseControl from './controls/MouseControl';
// import KeyboardControl from './controls/KeyboardControl';

const App = () => (
  <>
  <DisplayProvider>
    <World/>
    <Ui/>
      <Content/>
      <WalletTab/>
      <Console/>
      <Menu/>

    <Gallery/>

    {/* <MouseControl/> */}

  </DisplayProvider>
  </>
);

const container = document.getElementById('root');

if (!container._reactRootContainer) {
    const root = ReactDOM.createRoot(container);
    root.render(<App />);
  }

export default App;
