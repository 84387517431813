import { voronoi } from 'd3-voronoi';
import {large} from '../../utils/Colors.js';
import { createNoise3D } from 'simplex-noise';
import {lerpColor, rgbToHex} from '../../utils/Utils.js';

const noise = createNoise3D();

export const mondrian = {
    name: 'Aaru',
    generate: function (width, height, numStates) {
        let world = Array.from({ length: height }, () =>
            Array.from({ length: width }, () => [0, 1, []])
        );

        function fillArea(minX, minY, maxX, maxY, state) {
            for (let y = minY; y <= maxY; y++) {
                for (let x = minX; x <= maxX; x++) {
                    if (x === minX || x === maxX || y === minY || y === maxY) {
                        world[y][x][0] = 0;
                        world[y][x][1] = 9; // borders
                    } else {
                        world[y][x][0] = state;
                        world[y][x][1] = state;
                    }
                }
            }
        }

        function split(minX, minY, maxX, maxY, depth, forcedVertical = 0) {
            if (depth === 8 || maxX - minX < 20 || maxY - minY < 25) {
                let stateChoice = Math.floor(Math.random() * 8);
                fillArea(minX, minY, maxX, maxY, stateChoice);
                return;
            }

            if (forcedVertical < 1.5 || (Math.random() < 0.2 && forcedVertical < 1)) {
                let splitX = randInt(minX + Math.floor((maxX - minX) * 0.2), maxX - Math.floor((maxX - minX) * 0.2));
                split(minX, minY, splitX - 1, maxY, depth + 1, forcedVertical + 1);
                split(splitX + 1, minY, maxX, maxY, depth + 1, forcedVertical + 1);
            } else {
                let splitY = randInt(minY + Math.floor((maxY - minY) * 0.2), maxY - Math.floor((maxY - minY) * 0.2));
                split(minX, minY, maxX, splitY - 1, depth + 1, forcedVertical);
                split(minX, splitY + 1, maxX, maxY, depth + 1, forcedVertical);
            }
        }

        split(0, 1, width - .1, height - 2, 0);

        if (Math.random() < 1/3) {
        world = postProcessVoronoi(world, width, height);
        }
        
        world = postProcessElevation(world, width, height, numStates);

        world = postProcessReactionDiffusion(world, width, height, numStates);

        return postProcessing(world, width, height);
    }
}

function postProcessReactionDiffusion(world, width, height, numStates) {
    const visited = new Array(height).fill(false).map(() => new Array(width).fill(false));

    const isWithinBounds = (x, y) => x >= 0 && x < width && y >= 0 && y < height;

    const processCellGroup = (startX, startY) => {
        const queue = [[startX, startY]];
        const group = [];
        const valueToMatch = world[startY][startX][1];

        while (queue.length > 0) {
            const [x, y] = queue.shift();
            if (!isWithinBounds(x, y) || visited[y][x] || world[y][x][1] !== valueToMatch) continue;

            visited[y][x] = true;
            group.push([x, y]);

            for (let dx = -1; dx <= 1; dx++) {
                for (let dy = -1; dy <= 1; dy++) {
                    if (dx === 0 && dy === 0) continue;  // Skip the cell itself
                    let nx = x + dx, ny = y + dy;
                    if (isWithinBounds(nx, ny) && !visited[ny][nx] && world[ny][nx][1] === valueToMatch) {
                        queue.push([nx, ny]);
                    }
                }
            }
        }

        if (group.length >= 9) {
            group.forEach(([x, y]) => {
                const neighbors = [
                    isWithinBounds(x - 1, y) ? world[y][x - 1][1] : null,
                    isWithinBounds(x + 1, y) ? world[y][x + 1][1] : null,
                    isWithinBounds(x, y - 1) ? world[y - 1][x][1] : null,
                    isWithinBounds(x, y + 1) ? world[y + 1][x][1] : null,
                ].filter(n => n !== null);

                if (!neighbors.every(n => n === valueToMatch)) {
                    return;
                }

                if (valueToMatch === 1) {
                    world[y][x][2].reaction = true;
                }
            });
        }
    };

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            if (!visited[y][x] && world[y][x][1] === 1) {
                processCellGroup(x, y);
            }
        }
    }

    return reactionDiffusion(world, width, height, numStates);
}


function reactionDiffusion(world, width, height, numStates) {
    const newStates = 100;
    let diffusionWorld = Array.from({ length: height }, () =>
        Array.from({ length: width }, () => [0, Math.floor(Math.random() * newStates), {}])
    );

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            let cellState = diffusionWorld[y][x][1];
            let neighbors = getNeighborhood(diffusionWorld, x, y, width, height, 2);

            let differentStatesCount = neighbors.filter(([_, neighborState]) => Math.abs(neighborState - cellState) > newStates * 0.1).length;
            if (differentStatesCount > neighbors.length * 0.3) {
                cellState = (cellState + 10) % newStates;
            }

            let sumStates = neighbors.reduce((sum, [_, neighborState]) => sum + neighborState, cellState * 2);
            cellState = Math.floor(sumStates / (neighbors.length + 2));

            const normalizedValue = cellState / newStates;

            const color = lerpColor([45, 45, 45], [255, 255, 255], normalizedValue);
            diffusionWorld[y][x][2].diffusion = rgbToHex(color[0], color[1], color[2]);

            diffusionWorld[y][x][0] = normalizedValue * 10;
            diffusionWorld[y][x][2].reaction = true;
            diffusionWorld[y][x][2].symbol = '*';
        }
    }

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            if (world[y][x][2].reaction === true) {
                world[y][x] = diffusionWorld[y][x];
            }
        }
    }

    return world;
}  


function postProcessing(world, numStates) {
    let visited = Array.from({ length: world.length }, () => Array.from({ length: world[0].length }, () => false));

    function findArea(x, y, state) {
        let stack = [[x, y]];
        let bounds = { minX: x, maxX: x, minY: y, maxY: y };

        while (stack.length) {
            let [cx, cy] = stack.pop();

            if (cx < 0 || cy < 0 || cx >= world[0].length || cy >= world.length || visited[cy][cx] || world[cy][cx][1] !== state) {
                continue;
            }

            visited[cy][cx] = true;
            bounds.minX = Math.min(bounds.minX, cx);
            bounds.maxX = Math.max(bounds.maxX, cx);
            bounds.minY = Math.min(bounds.minY, cy);
            bounds.maxY = Math.max(bounds.maxY, cy);

            stack.push([cx + 1, cy], [cx - 1, cy], [cx, cy + 1], [cx, cy - 1]);
        }

        if (state === 0) {
            processState0(bounds.minX, bounds.minY, bounds.maxX, bounds.maxY, world, numStates);
        }
    }

    for (let y = 0; y < world.length; y++) {
        for (let x = 0; x < world[y].length; x++) {
            if (!visited[y][x] && (world[y][x][1] === 0 || world[y][x][1] === 6)) {
                findArea(x, y, world[y][x][1]);
            }
        }
    }

    return world;
}

function postProcessVoronoi(world, width, height) {
    const startIndex = Math.floor(Math.random() * large.length);
  
    const sites = [];
    for (let i = 0; i < 54; i++) {
      sites.push({ x: Math.random() * width, y: Math.random() * height });
    }
  
    const voronoiDiagram = voronoi().extent([[0, 0], [width, height]]).x(d => d.x).y(d => d.y);
    const diagram = voronoiDiagram(sites);
  
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const site = diagram.find(x, y);
        if (site) {
          const colorIndex = (sites.indexOf(site.data) + startIndex) % large.length;
          const color = large[colorIndex];
          if (world[y][x][1] === 5 || world[y][x][1] === 6) {
            world[y][x][2].bgColor = color;
            world[y][x][2].voronoi = true;
          }
        }
      }
    }
  
    return world;
  }

  function postProcessElevation(world, width, height, numStates) {
    let noiseZ = 0;
    const squareSize = Math.min(width, height);
  
    const noiseScaleX = squareSize / 5;
    const noiseScaleY = squareSize / 5;
  
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        if (world[y][x][2].voronoi) {
          const value = noise(x / noiseScaleX, y / noiseScaleY, noiseZ);
          const elevation = (value + 1) / 2 * 9;
  
          if (elevation < 7) {
            const sineAdjustment = Math.sin(elevation / 6 * Math.PI / 2) * 0.5 + 6.69;
            world[y][x][0] = sineAdjustment / 9;
          } else {
            world[y][x][0] = elevation / 10;
          }
        }
      }
    }
  
    return world;
  }

function processState0(minX, minY, maxX, maxY, world, numStates) {

    minX = Math.max(minX, 0);
    minY = Math.max(minY, 0);
    maxX = Math.min(maxX, world[0].length - 1);
    maxY = Math.min(maxY, world.length - 1);

    for (let iteration = 0; iteration < 10; iteration++) {
        let nextWorld = Array.from({ length: maxY - minY + 1 }, () => new Array(maxX - minX + 1).fill().map(() => [0, 0, []]));

        for (let y = minY; y <= maxY; y++) {
            for (let x = minX; x <= maxX; x++) {
                let neighbors = getNeighborhood(world, x, y, world[0].length, world.length);
                let cellState = world[y][x][1];
                let differentStatesCount = neighbors.filter(neighbor => neighbor[1] !== cellState).length;

                // Reaction
                if (differentStatesCount > neighbors.length / 2) {
                    nextWorld[y - minY][x - minX][1] = (cellState + 1) % numStates;
                } else {
                    nextWorld[y - minY][x - minX][1] = cellState;
                }

                // Diffusion
                let sumStates = neighbors.reduce((sum, neighbor) => sum + neighbor[1], 0);
                nextWorld[y - minY][x - minX][1] = Math.floor(sumStates / (neighbors.length));
            }
        }

        for (let y = minY; y <= maxY; y++) {
            for (let x = minX; x <= maxX; x++) {
                world[y][x][1] = nextWorld[y - minY][x - minX][1];
            }
        }
    }
}

function getNeighborhood(world, x, y, width, height) {
    let neighbors = [];
    for (let dx = -1; dx <= 1; dx++) {
        for (let dy = -1; dy <= 1; dy++) {
            if (dx === 0 && dy === 0) continue;
            let nx = x + dx;
            let ny = y + dy;
            if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
                neighbors.push(world[ny][nx]);
            }
        }
    }
    return neighbors;
}

function randInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}


