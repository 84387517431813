import React, { useContext, useEffect } from 'react';
import { useDisplay } from '../../../context/DisplayContext';
import {clearContainer} from '../utils/Utils';

const Console = () => {
    const { display, containerCoords, consoleTabOpen, consoleMessages, walletTabOpen } = useDisplay();

    useEffect(() => {
        if (!display || !containerCoords || containerCoords.length === 0) return;

        clearContainer(display, containerCoords[1], 0, '#FFFFFF');

        if(consoleTabOpen) {

            display.drawText(containerCoords[1].startX + 3, containerCoords[1].startY + 1, '%b{#FFFFFF}%c{#141F60}--- CONSOLE');
            for (let i = 2; i <= 11; i++) {
                display.drawText(containerCoords[1].startX + 2, containerCoords[1].startY + i, '%b{#FFFFFF}%c{#141F60}|');
            }

            if(!walletTabOpen) {
                display.drawText(containerCoords[1].startX + 2, containerCoords[1].startY + 12, '%b{#FFFFFF}%c{#141F60}└───────────────────── %b{#000}%c{#FFFFFF}<x>%b{#FFFFFF}%c{#141F60} CLOSE');
            } else {
                display.drawText(containerCoords[1].startX + 2, containerCoords[1].startY + 12, '%b{#FFFFFF}%c{#141F60}└───────────────────────────────');
            }
            
            consoleMessages.forEach((message, index) => {
                display.drawText(containerCoords[1].startX + 4, containerCoords[1].endY - 2 - index, `%c{#FFFFFF}%b{#141F60}${message}`);
            });

        } else {
            display.drawText(containerCoords[1].startX + 4, containerCoords[1].startY + 1, '%b{#000}%c{#FFFFFF}<c>%b{#FFFFFF}%c{#141F60} OPEN CONSOLE');
        }
        
    }, [display, containerCoords, consoleTabOpen, consoleMessages, walletTabOpen]);

    return null;
};

export default Console;
