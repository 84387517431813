import { gradient } from '../../utils/Colors.js';
import { lerpColor, rgbToHex, hexToRgb } from '../../utils/Utils.js';

const gradientStartIndex = Math.floor(Math.random() * gradient.length);


export const fire = {
  name: 'fire',
  updateRule: (currentWorld, getNeighbors, numStates, width, height) => {
    const newWorld = currentWorld.map(row => row.map(cell => [
      cell[0],
      cell[1],
      Array.isArray(cell[2]) ? [...cell[2]] : { bgColor: '#000000', burnTime: 0 } // Ensure a default structure
    ]));

    const fireState = numStates - 1;
    const regrownState = 1;
    const emptyState = 0;
    const maxBurnTime = 10;  // Increased burn time
    const propagationChance = 0.5;

    // Ignite a random cell
    const fireX = Math.floor(Math.random() * width);
    const fireY = Math.floor(Math.random() * height);
    if (newWorld[fireY] && newWorld[fireY][fireX]) {
      newWorld[fireY][fireX][1] = fireState;
      newWorld[fireY][fireX][2] = [{ burnTime: maxBurnTime }];
      newWorld[fireY][fireX][2].bgColor = '#ffffff'
      newWorld[fireY][fireX][2].fontColor = '#000000'
    }

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        if (newWorld[y] && newWorld[y][x]) {
          const cellState = newWorld[y][x][1];
          let cellData = newWorld[y][x][2][0] || {};

          if (cellState === fireState && cellData.burnTime > 0) {
            cellData.burnTime--;

            const gradientIndex = (x + gradientStartIndex) % gradient.length;
            // newWorld[y][x][2].bgColor = gradient[gradientIndex];
            newWorld[y][x][2].bgColor = '#000000'; // for render

            newWorld[y][x][2].gradient = true;

            newWorld[y][x][2][0] = {
              ...cellData,
            };


            if (Math.random() < propagationChance && cellData.burnTime > maxBurnTime / 2) {
              const neighbors = getNeighborhood(newWorld, x, y, width, height);
              neighbors.forEach(({ x: nx, y: ny }) => {
                if (newWorld[ny] && newWorld[ny][nx] && newWorld[ny][nx][1] !== fireState && newWorld[ny][nx][1] !== emptyState) {
                  newWorld[ny][nx][1] = fireState;
                  newWorld[ny][nx][2][0] = {
                    burnTime: maxBurnTime,
                  };

                }
              });
            }

            if (cellData.burnTime === 0) {
              newWorld[y][x][1] = emptyState;
            }
          } else if (cellState === emptyState && Math.random() < 0.01) {
            newWorld[y][x][1] = regrownState;
          }
        }
      }
    }

    return newWorld;
  }
};

function getNeighborhood(world, x, y, width, height) {
  const neighbors = [];
  for (let dx = -1; dx <= 1; dx++) {
    for (let dy = -1; dy <= 1; dy++) {
      if (dx === 0 && dy === 0) continue;
      const nx = x + dx;
      const ny = y + dy;
      if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
        neighbors.push({ x: nx, y: ny });
      }
    }
  }
  return neighbors;
}
