const getNeighbors = (world, x, y, worldWidth, worldHeight) => {
  const offsets = [[-1, 0], [1, 0], [0, -1], [0, 1]];
  return offsets.map(([dx, dy]) => {
    const newX = x + dx, newY = y + dy;
    if (newX >= 0 && newX < worldWidth && newY >= 0 && newY < worldHeight && world[newY] !== undefined) {
      return world[newY][newX];
    }
    return null;
  }).filter(neighbor => neighbor !== null);
};

function lerpColor(color1, color2, t) {
  const r = Math.round(color1[0] * (1 - t) + color2[0] * t);
  const g = Math.round(color1[1] * (1 - t) + color2[1] * t);
  const b = Math.round(color1[2] * (1 - t) + color2[2] * t);
  return [r, g, b];
}

function rgbToHex(r, g, b) {
  const toHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return '#' + toHex(r) + toHex(g) + toHex(b);
}

const hexToRgb = (hex) => {
  if (!hex || typeof hex !== 'string' || hex[0] !== '#') {
    console.error("Invalid or missing hex color:", hex);
    return [0, 0, 0];
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};

function desaturateColor(hex) {
  const [r, g, b] = hexToRgb(hex);
  const gray = Math.round(0.3 * r + 0.59 * g + 0.11 * b);
  return rgbToHex(gray, gray, gray);
}

function getRandomNumber(min, max) {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}


export { lerpColor, rgbToHex, hexToRgb, desaturateColor, getNeighbors, getRandomNumber }