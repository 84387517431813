import React, { useState, useEffect, useRef } from 'react';
import { AsyncImage } from 'loadable-image';
import { Blur } from 'transitions-kit';

const useImagePaths = (totalImages) => {
  const [imagePaths, setImagePaths] = useState(
    Array(totalImages).fill({
      thumb: '/render/placeholder.png',
      highRes: null,
      mediumRes: null,
      lowRes: null,
      mp4: null,
    })
  );

  useEffect(() => {
    const checkImageExists = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = src;
      });
    };

    const loadImagePaths = async () => {
      for (let i = 0; i < totalImages; i++) {
        const num = i + 1;
        let paths = {
          thumb: '/render/placeholder.png',
          highRes: null,
          mediumRes: null,
          lowRes: null,
          mp4: null,
        };

        const thumbPaths = [
          `/render/${num}-thumb.jpeg`,
          `/render/${num}-thumb.png`,
          `/render/${num}-thumb.gif`,
        ];

        let foundThumb = false;
        let baseExtension = '';

        for (const thumbPath of thumbPaths) {
          const exists = await checkImageExists(thumbPath);
          if (exists) {
            paths.thumb = thumbPath;
            baseExtension = thumbPath.split('.').pop();
            foundThumb = true;
            break;
          }
        }

        if (foundThumb) {
          if (baseExtension === 'jpeg') {
            paths.highRes = `/render/${num}.jpeg`;
            paths.mediumRes = `/render/${num}-4096.jpeg`;
            paths.lowRes = `/render/${num}-1600.jpeg`;
          } else if (baseExtension === 'png') {
            paths.highRes = null;
            paths.mediumRes = `/render/${num}-4096.png`;
            paths.lowRes = `/render/${num}-1600.png`;
          } else if (baseExtension === 'gif') {
            paths.highRes = null;
            paths.mediumRes = `/render/${num}-4096.gif`;
            paths.lowRes = `/render/${num}-1600.gif`;
            paths.mp4 = await checkImageExists(`/render/${num}.mp4`) ? `/render/${num}.mp4` : null;
          }
        } else {
          paths.thumb = '/render/placeholder.png';
        }

        setImagePaths((prevPaths) => {
          const newPaths = [...prevPaths];
          newPaths[i] = paths;
          return newPaths;
        });
      }
    };

    loadImagePaths();
  }, [totalImages]);

  return imagePaths;
};

const Gallery = () => {
  const totalImages = 100;
  const [isLoading, setIsLoading] = useState(true);
  const [loadedThumbnails, setLoadedThumbnails] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const imagePaths = useImagePaths(totalImages);

  useEffect(() => {
    const loadedThumbsCount = imagePaths.filter(({ thumb }) => thumb !== '/render/placeholder.png').length;

    if (loadedThumbsCount >= 9 && !hasScrolled) {
      setIsLoading(false);
      setHasScrolled(true);

      window.scrollTo({
        top: window.scrollY + window.innerHeight * 0.5,
        behavior: 'smooth',
      });
    }
  }, [imagePaths, hasScrolled]);

  return (
    <>
      {isLoading && (
        <></>
        // <div className="loading-state">
        //   Loading gallery, please wait...
        // </div>
      )}
      <div id="gallery" className='hidden'>
        <div className='section'>
          {imagePaths.map(({ thumb, highRes, mediumRes, lowRes, mp4 }, index) => {
            if (thumb === '/render/placeholder.png') return null;
            return (
              <div key={index} className='image-container'>
                <a href={highRes || mediumRes || lowRes} target='_blank' rel='noopener noreferrer' className='hd'>
                  <AsyncImage
                    src={thumb}
                    style={{ width: '100%', height: 'auto' }}
                    Transition={Blur}
                    onLoad={() => setLoadedThumbnails(prev => prev + 1)}
                  />
                </a>
                <div className='image-links'>
                  {highRes && highRes.endsWith('.jpeg') && (
                    <>
                      <a href={highRes} target='_blank' rel='noopener noreferrer'>
                        8192px
                      </a>
                      <a href={mediumRes} target='_blank' rel='noopener noreferrer'>
                        4096px
                      </a>
                    </>
                  )}
                  {mediumRes && !highRes && (
                    <a href={mediumRes} target='_blank' rel='noopener noreferrer'>
                      4096px
                    </a>
                  )}
                  <a href={`${lowRes}.${thumb.split('.').pop()}`} target='_blank' rel='noopener noreferrer'>
                    1600px
                  </a>
                  {mp4 && (
                    <a href={mp4} target='_blank' rel='noopener noreferrer'>
                      mp4
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      </>
  );
};

export default Gallery;
