import { lerpColor, rgbToHex, hexToRgb, desaturateColor, getRandomNumber } from '../utils/Utils.js';
import { createNoise3D } from 'simplex-noise';

const noise = createNoise3D();
let noiseZ = 10;

export const postProcessFontColor = (setWorld) => {
    setWorld(currentWorld => {
      return currentWorld.map(row =>
        row.map(cell => {
          const [elevation, value, data] = cell;
  
          let fontColorHex;
          if (elevation < 0.2) {
            fontColorHex = '#000000';
          } else if (elevation >= 0.2 && elevation <= 0.24) {
            const t = (elevation - 0.2) / 0.1;
            const color = lerpColor([0, 0, 0], [255, 255, 255], t);
            fontColorHex = rgbToHex(color[0], color[1], color[2]);
          } else {
            const newFontColor = lerpColor([69, 69, 69], [255, 255, 255], elevation);
            fontColorHex = rgbToHex(newFontColor[0], newFontColor[1], newFontColor[2]);
          }
  
          return [
            elevation,
            value,
            { ...data, fontColor: fontColorHex }
          ];
        })
      );
    });
  };

  export const postProcessAnimation = (setWorld, dimensions) => {
    const squareSize = Math.min(dimensions.widthInTiles, dimensions.heightInTiles);

    noiseZ += 0.005;

    const noiseScaleX = squareSize / 0.99;
    const noiseScaleY = squareSize / 0.99;

    setWorld(currentWorld => {
      return currentWorld.map((row, y) => {
        return row.map((cell, x) => {
          const [elevation, value, data] = cell;

          const noiseValue = noise(x / noiseScaleX, y / noiseScaleY, noiseZ);
          const normalizedElevation = (noiseValue + 1) / 2;

          if (normalizedElevation >= 0.85) {
            const [r1, g1, b1] = hexToRgb(data.bgColor);
            const [r2, g2, b2] = hexToRgb("#ffffff");
            const [rNewBg, gNewBg, bNewBg] = lerpColor([r1, g1, b1], [r2, g2, b2], 0.01);
            const newBgColor = rgbToHex(rNewBg, gNewBg, bNewBg);

            const [r1f, g1f, b1f] = hexToRgb(data.fontColor);
            const [rNewFont, gNewFont, bNewFont] = lerpColor([r1f, g1f, b1f], [0, 0, 0], 0.015);
            const newFontColor = rgbToHex(rNewFont, gNewFont, bNewFont);

            return [elevation, value, { ...data, bgColor: newBgColor, fontColor: newFontColor }];
          }

          return cell;
        });
      });
    });
  };

  export const processHeightMap = (x, y, cellData, elevation, generationConf, noise, noiseZ) => {
    let fontColor = cellData.fontColor || '#FFF';
    let bgColor = cellData.bgColor || '#000';
    let symbol = cellData.symbol || '-';
  
    const baseColor = lerpColor([69, 69, 69], [255, 255, 255], elevation);
    fontColor = rgbToHex(baseColor[0], baseColor[1], baseColor[2]);
  
    if (generationConf.name === "Neteru") {
      const desaturatedBgColor = desaturateColor(bgColor, 0.5);
      const noiseValue = noise(x / 100, y / 100, noiseZ);
      const normalizedValue = (noiseValue + 1) / 2;
      const roundedNormalizedValue = Math.round(normalizedValue * 1000) / 1000;
  
      let color = hexToRgb(desaturatedBgColor);
      color = lerpColor(color, [169, 169, 169], roundedNormalizedValue);
      bgColor = rgbToHex(color[0], color[1], color[2]);
  
      if (cellData.voronoi) {
        if (cellData.value === 1) {
          fontColor = "#666666";
        } else if (cellData.value === 2) {
          fontColor = "#777777";
        } else if (cellData.value === 3) {
          fontColor = "#888888";
        }
      } else {
        fontColor = "#ffffff";
      }
    }
  
    if (generationConf.name === "Khamsin") {
      if (cellData.noise && elevation >= 0.2 && elevation <= 0.24) {
        const t = (elevation - 0.2) / 0.1;
        const noiseColor = lerpColor([0, 0, 0], [255, 255, 255], t);
        fontColor = rgbToHex(noiseColor[0], noiseColor[1], noiseColor[2]);
      }
      if (elevation < 0.2) {
        fontColor = '#000000';
      }
    }
  
    if (generationConf.name === "Aaru") {
      if (cellData.voronoi) {
        bgColor = rgbToHex(baseColor[0], baseColor[1], baseColor[2]);
      }
      if (cellData.reaction) {
        bgColor = cellData.diffusion;
        symbol = '*';
        fontColor = "#ffffff";
      }
      if (cellData.value === 1) {
        fontColor = "#dddddd";
      } else if (cellData.value === 2) {
        fontColor = "#555555";
      }
  
      if (cellData.isPattern && !cellData.voronoi) {
        if (cellData.value === 1) {
          fontColor = "#666666";
        } else if (cellData.value === 2) {
          fontColor = "#777777";
        } else if (cellData.value === 3) {
          fontColor = "#888888";
        }
      }
  
      if (cellData.isPattern && cellData.voronoi) {
        const [r, g, b] = hexToRgb(bgColor);
        const lerpedColor = lerpColor([r, g, b], [0, 0, 0], 0.01);
        fontColor = rgbToHex(lerpedColor[0], lerpedColor[1], lerpedColor[2]);
      }
    }
  
    if (generationConf.name === "Sekhmet-s Wrath") {
      const voidColors = ["#dddddd", "#999999", "#333333"];
      if (cellData.value === 0) {
        fontColor = "#666666";
      } else if (cellData.value === 1) {
        fontColor = "#777777";
      } else if (cellData.value === 2) {
        fontColor = "#888888";
      } else if (cellData.value === 9) {
        fontColor = "#666666";
      }
      if (cellData.gradient) {
        const gradientColor = lerpColor([69, 69, 69], [255, 255, 255], getRandomNumber(0, 9));
        bgColor = rgbToHex(gradientColor[0], gradientColor[1], gradientColor[2]);
        fontColor = "#ffffff";
      }
    }
  
    return { fontColor, bgColor, symbol };
  };