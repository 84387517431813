import { createNoise3D } from 'simplex-noise';
import { lerpColor, rgbToHex } from '../../utils/Utils.js';

const noise = createNoise3D();
let noiseZ = 10;

export const ruleBased = {
    name: "Mehen",
    generate: (width, height, numStates) => {
        let world = [];

        let generationIsValid = false;
        let attempts = 0;

        while (!generationIsValid && attempts < 99) {
            const randomRuleSet = generateRandomRuleSet(numStates);

            world = Array.from({ length: height }, () =>
                Array.from({ length: width }, () => [0, 0, []])
            );

            world[0][Math.floor(width / 2)] = [0, 1, []];

            for (let y = 1; y < height; y++) {
                for (let x = 0; x < width; x++) {
                    let cellValue;

                    if (y === 1) {
                        const leftValue = world[y - 1][x - 1 < 0 ? width - 1 : x - 1][1];
                        const centerValue = world[y - 1][x][1];
                        const rightValue = world[y - 1][(x + 1) % width][1];
                        const selectedRule = rules[Math.floor(Math.random() * rules.length)];
                        cellValue = selectedRule(leftValue, centerValue, rightValue);
                    } else {
                        const neighborhood = [
                            world[y-1][x-2 < 0 ? width + x-2 : x-2][1],
                            world[y-1][x-1 < 0 ? width + x-1 : x-1][1],
                            world[y-1][x][1],
                            world[y-1][(x+1) % width][1],
                            world[y-1][(x+2) % width][1],
                        ];
                        const neighborhoodState = parseInt(neighborhood.join(''), numStates);
                        cellValue = applyRule(randomRuleSet, neighborhoodState);
                    }

                    const normalizedValue = cellValue / (numStates - 1);
                    const color = lerpColor([0, 0, 0], [45, 45, 45], normalizedValue);
                    let hexColor = rgbToHex(color[0], color[1], color[2]);

                    let font = "#fff"

                    if (normalizedValue === 0) {
                        font = '#636363';
                      } 

                      if (normalizedValue === 1) {
                        font = '#808080';
                      } 

                      if (normalizedValue === 2) {
                        font = '#dedede';
                      } 

                    world[y][x] = [normalizedValue, cellValue, { isCreature: true, bgColor: hexColor, fontColor: font }];
                }
            }

            if (Math.random() < 1/3) {
                world = postProcessing(world, height, numStates);
            }
            generationIsValid = checkDiversity(world, numStates);
            attempts++;
        }

        return world;
    }
};

function postProcessing(world, height, numStates) {
    const width = world[0].length;
    const threshold = Math.floor(width * 0.4);

    for (let y = 0; y < world.length; y++) {
        let currentLength = 1;
        let lastColor = world[y][0][1];

        for (let x = 1; x < width; x++) {
            if (world[y][x][1] === lastColor) {
                currentLength++;
            } else {
                if (currentLength >= threshold) {
                    for (let k = x - currentLength; k < x; k++) {
                        world[y][k][2].bgColor = '#0000FF';
                    }
                    if (x < width && world[y][x][1] !== lastColor) {
                        world[y][x - 1][2].bgColor = '#000';
                        world[y][x - 1][2].bgColor = '#fff';
                        world[y][x - 1][2].symbol = '.';
                    }
                }
                currentLength = 1;
                lastColor = world[y][x][1];
            }
        }
        if (currentLength >= threshold) {
            for (let k = width - currentLength; k < width; k++) {
                world[y][k][2].bgColor = '#0000FF';
            }
        }
    }

    for (let y = 0; y < world.length; y++) {
        let row = world[y];
        if (row.every(cell => cell[1] === row[0][1])) {
            for (let x = 0; x < row.length; x++) {
                row[x][2].bgColor = '#FF0000';
            }
        }
    }

    return noisePp(world, width, height, numStates);

}

function noisePp(world, width, height, numStates) {
    noiseZ += 0.005;

    const newWorld = [];
    const visited = Array.from({ length: height }, () => Array(width).fill(false));

    // for (let y = 0; y < height; y++) {
    //     for (let x = 0; x < width; x++) {
    //         if (world[y][x][2].bgColor === '#0000FF' && !visited[y][x]) {
    //             floodFill(world, x, y, width, height, visited, gradient, x, y);
    //         }
    //     }
    // }

    for (let y = 0; y < height; y++) {
        const row = [];
        for (let x = 0; x < width; x++) {
            const cell = world[y][x];
            let bgColor = cell[2].bgColor;

            if (bgColor === '#0000FF') {
                const value = noise(x / 100, y / 100, noiseZ);
                const normalizedValue = (value + 1) / 2;
                const roundedNormalizedValue = Math.round(normalizedValue * 1000) / 1000;

                const stateValue = Math.floor((value + 1) * 10 * numStates);

                const color = lerpColor([1, 1, 1], [69, 69, 69], roundedNormalizedValue);
                const newHexColor = rgbToHex(color[0], color[1], color[2]);

                let fontColor = '#FFF'; 
                if (roundedNormalizedValue < 0.2) {
                    fontColor = '#000';
                } else if (roundedNormalizedValue >= 0.2 && roundedNormalizedValue <= 0.24) {
                    const t = (roundedNormalizedValue - 0.2) / 0.04;
                    const color = lerpColor([0, 0, 0], [255, 255, 255], t);
                    fontColor = rgbToHex(color[0], color[1], color[2]);
                } else if (roundedNormalizedValue >= 0.7 && roundedNormalizedValue <= 0.9) {
                    const t = (roundedNormalizedValue - 0.2) / 0.04;
                    const color = lerpColor([0, 0, 0], [255, 255, 255], t);
                    fontColor = rgbToHex(color[0], color[1], color[2]);
                }

                row.push([roundedNormalizedValue, stateValue, { noise: true, bgColor: newHexColor, fontColor }]);

            } else {
                row.push(cell);
            }
        }
        newWorld.push(row);
    }
    



    return newWorld;
}

export function getNeighborhood(world, x, y, width, height, size = 2) {
    let neighbors = [];
    for (let dx = -size; dx <= size; dx++) {
      for (let dy = -size; dy <= size; dy++) {
        if (dx === 0 && dy === 0) continue;
        let nx = x + dx;
        let ny = y + dy;
        if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
            neighbors.push(world[ny][nx]);
        }
      }
    }
    return neighbors;
  }


function generateRandomRuleSet(numStates) {
    let ruleSet = {};
    for (let i = 0; i < Math.pow(numStates, 5); i++) {
        ruleSet[i] = Math.floor(Math.random() * numStates);
    }
    return ruleSet;
}

function createRule(r) {
    return (left, center, right) => {
        const binaryState = (left << 2) | (center << 1) | right;
        return ((r >> binaryState) & 1);
    };
}

const rules = [
    createRule(181),
    createRule(167),
    createRule(151),
    createRule(149),
    createRule(121),
    createRule(105),
    createRule(99),
    createRule(93),
    createRule(77),
    createRule(69),
    createRule(57),
    createRule(13),
];

function applyRule(ruleSet, neighborhoodState) {
    return ruleSet[neighborhoodState] || 0;
}


function checkDiversity(world, numStates) {
    let hasRedRow = world.some(row => row.some(cell => cell[2].bgColor === '#FF0000'));
    if (hasRedRow) return false;

    let cellCounts = new Array(numStates).fill(0);
    world.flat().forEach(cell => {
        if (cell[1] >= 0 && cell[1] < numStates) {
            cellCounts[cell[1]]++;
        }
    });

    const relevantCells = cellCounts.reduce((acc, count) => acc + count, 0);
    const maxCount = Math.max(...cellCounts);
    const maxProportion = maxCount / relevantCells;
    return maxProportion < 0.45;
}

function floodFill(world, x, y, width, height, visited, gradient, xStart, yStart) {
    let baseIndex = Math.floor(Math.random() * gradient.length);
  
    let queue = [{ x, y, index: baseIndex }];
  
    while (queue.length > 0) {
      let { x, y, index } = queue.shift();
  
      if (x < 0 || x >= width || y < 0 || y >= height || visited[y][x] || world[y][x][0] < 0.7 || world[y][x][0] > 0.9) continue;
      visited[y][x] = true;
  
      world[y][x][2].fontColor = gradient[index];
  
      let nextIndex = (index + 1) % gradient.length;
  
      enqueueIfValid(queue, x + 1, y, width, height, visited, nextIndex);
      enqueueIfValid(queue, x - 1, y, width, height, visited, nextIndex);
      enqueueIfValid(queue, x, y + 1, width, height, visited, nextIndex);
      enqueueIfValid(queue, x, y - 1, width, height, visited, nextIndex);
      enqueueIfValid(queue, x + 1, y + 1, width, height, visited, nextIndex);
      enqueueIfValid(queue, x - 1, y - 1, width, height, visited, nextIndex);
      enqueueIfValid(queue, x + 1, y - 1, width, height, visited, nextIndex);
      enqueueIfValid(queue, x - 1, y + 1, width, height, visited, nextIndex);
    }
  }
  
  function enqueueIfValid(queue, x, y, width, height, visited, index) {
    if (x >= 0 && x < width && y >= 0 && y < height && !visited[y][x]) {
      queue.push({ x, y, index });
    }
  }