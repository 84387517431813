export const cyclic = {
    name: 'cyclic',
    updateRule: (currentWorld, getNeighbors, numStates, worldWidth, worldHeight) => {
        return currentWorld.map((row, y) => row.map((cell, x) => {
            if (!Array.isArray(cell) || cell.length < 3) {
                cell = [0, cell, {}];
            }

            const [binary, value, data] = cell;

            const nextState = (value + 1) % numStates;
            const neighbors = getNeighbors(currentWorld, x, y, worldWidth, worldHeight)
                .map(neighbor =>
                    Array.isArray(neighbor) ? neighbor[1] : neighbor
                );

            if (neighbors.some(neighborValue => neighborValue === nextState)) {
                return [binary, nextState, data];
            }

            return cell;
        }));
    }
};
