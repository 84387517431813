import { useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import styleConfurations from './conf/Style';
import { useDisplay } from '../context/DisplayContext';

const useKeyPressHandlers = (
  setStyleConf,
  setIsRunning,
  postProcessFontColor,
  postProcessAnimation,
  heightMap,
  setHeightMap,
  showUI,
  setShowUI,
  regen,
  setWorld,
  dimensions,
  display,
  exportInProgress,
  setExportInProgress,
  engine,
  scheduler,
  automataConfurations,
  worldAct
) => {

  const { activeTab, setActiveTab, walletTabOpen, setWalletTabOpen, setConsoleTabOpen, addToConsole } = useDisplay();

  const exportCanvas = (display, setExportInProgress) => {
    if (exportInProgress) return;
    setExportInProgress(true);

    setTimeout(() => {
      const canvas = display.getContainer();
      const dataURL = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'export.png';
      link.click();

      setTimeout(() => { setExportInProgress(false); setShowUI(true); }, 500);
    }, 100);
  };

  const handleKeyPress = useCallback((event) => {
    switch (event.key) {
      case 's':
        setStyleConf(selectRandomStyle());
        break;
      case 'p':
        // setIsRunning(running => !running);
        break;
      case 'e':
        setShowUI(false);
        exportCanvas(display, setExportInProgress);
        break;
      case 'h':
        setHeightMap(!heightMap);
        break;
      case 'j':
        postProcessFontColor(setWorld);
        break;
      case 'k':
        postProcessAnimation(setWorld, dimensions);
        break;
      case 'i':
        addToConsole('inventory opened')
        setWalletTabOpen(true)
      break;
      case 'c':
        setConsoleTabOpen(true);
      break;
      case 'x':
        if(walletTabOpen) {
          addToConsole('inventory closed')
          setWalletTabOpen(false);
      } else if(!walletTabOpen) {
          setConsoleTabOpen(false);
      }
      break;
      case 'ArrowDown':
        addToConsole('scroll into Container')
      break;
      case '1':
        debouncedRegen('Khamsin');
        addToConsole('')
        addToConsole('TAKING PORTAL TO KHAMSIN')
        setActiveTab(1);
        break;
      case '2':
        debouncedRegen('Mehen');
        addToConsole('')
        addToConsole('TAKING PORTAL TO MEHEN')
        setActiveTab(2);
        break;
      case '3':
        debouncedRegen('Neteru');
        addToConsole('')
        addToConsole('TAKING PORTAL TO NETERU')
        setActiveTab(3);
        break;
      case '4':
        debouncedRegen('Aaru');
        addToConsole('')
        addToConsole('TAKING PORTAL AARU')
        setActiveTab(4);
        break;
      case '5':
        debouncedRegen('Sekhmet-s Wrath');
        addToConsole('')
        addToConsole('TAKING PORTAL TO SEKHMET\'S WRATH')
        setActiveTab(5);
        break;
      case '6':
        setActiveTab(6)
        removeHiddenClass();
        break;
      case 'Escape':
        addHiddenClass();
        break;
      case 'a':
        // if(walletTabOpen) {
        addToConsole('')
        addToConsole('UNLEASHING SEKHMET\'S FIRE...')
        // }
        addFireAutomataWorldAct(scheduler, engine, automataConfurations, worldAct);
        break;
      default:
        break;
    }
  }, [
    setStyleConf,
    setIsRunning,
    heightMap,
    setHeightMap,
    postProcessFontColor,
    postProcessAnimation,
    showUI,
    setShowUI,
    regen,
    display,
    exportInProgress,
    setExportInProgress,
    engine,
    scheduler,
    automataConfurations,
    worldAct
  ]);

  const debouncedRegen = debounce((generationName) => {
    regen(generationName);
  }, 100);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);
};

const removeHiddenClass = () => {
  const element = document.getElementById('gallery');
  if (element) {
    element.classList.remove('hidden');
  }
};

const addHiddenClass = () => {
  const element = document.getElementById('gallery');
  if (element) {
    element.classList.add('hidden');
  }
};

const addFireAutomataWorldAct = (scheduler, engine, automataConfurations, worldAct) => {
  if (!scheduler || !engine) {
    console.warn('Scheduler or engine not available.');
    return;
  }

  // Define the 'fire' worldAct
  const fireAutomata = automataConfurations.find(a => a.name === 'fire');
  if (!fireAutomata) {
    console.warn('Fire automata configuration not found.');
    return;
  }

  const worldActWithFire = () => {
    if (!engine) return;

    engine.lock();
    setTimeout(() => {
      engine.unlock();
    }, 1000 / 60); // Assuming 60 FPS for the fire automata world act
  };

  // Add the fire world act to the scheduler
  console.log('Adding fire automata world act to scheduler');
  scheduler.add({
    act: () => worldAct(fireAutomata)
  }, true);
};

const selectRandomStyle = () => {
  const styleChoices = styleConfurations;
  return styleChoices[Math.floor(Math.random() * styleChoices.length)];
};

export default useKeyPressHandlers;
