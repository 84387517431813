import { large } from '../../utils/Colors.js';
import { voronoi } from 'd3-voronoi';

export const voronoy = {
    name: "Neteru",
    generate: (width, height, numStates) => {
        let world;
        let attempts = 0;
        const maxAttempts = 69;

        do {
            const rule = generateRandomRule(numStates);
            const colors = ['#FFFFFF', '#000000', '#0000FF'];
            const automaton = new CellularAutomaton(rule, 3, colors, width, height);
            automaton.generate();
            world = automaton.getWorld();
            attempts++;
        } while (attempts < maxAttempts && !checkDiversity(world));

        world = postProcessVoronoi(world, width, height);

        return world;
    }
};

function generateRandomRule(states) {
    const ruleSize = Math.pow(states, 3);
    let rule = '';
    for (let i = 0; i < ruleSize; i++) {
        rule += Math.floor(Math.random() * states).toString(states);
    }
    return rule;
}

class CellularAutomaton {
    constructor(rule, neighborhood = 3, colors, width, height) {
        this.rule = rule;
        this.neighborhood = neighborhood;
        this.colors = colors;
        this.states = colors.length;
        this.width = width;
        this.height = height;
        this.map = Array.from({ length: height }, () => Array(width).fill(0));
        this.key = this.generateKey(rule, neighborhood, this.states);
    }

    generateKey(rule, neighborhood, states) {
        const ruleArray = rule.split('').reverse().map(num => parseInt(num, states));
        return this.buildKey(neighborhood, states, ruleArray);
    }

    buildKey(neighborhood, states, ruleArray, index = [0]) {
        const build = (depth) => {
            if (depth === 0) {
                return ruleArray[index[0]++];
            }
            const node = new Array(states);
            for (let i = 0; i < states; i++) {
                node[i] = build(depth - 1);
            }
            return node;
        };
        return build(neighborhood);
    }

    newCell(x, y, state) {
        if (x >= 0 && x < this.width && y >= 0 && y < this.height) {
            this.map[y][x] = state;
        }
    }

    checkCells(x, y) {
        let array = this.key;
        const startX = x - Math.floor(this.neighborhood / 2);
        const endX = x + Math.floor(this.neighborhood / 2);
        for (let i = startX; i <= endX; i++) {
            if (i < 0 || i >= this.width) {
                return 0;
            }
            const state = this.map[y - 1][i];
            if (array[state] === undefined) {
                return 0;
            }
            array = array[state];
        }
        return array;
    }

    generate() {
        this.newCell(Math.floor(this.width / 2), 1, 1);
        for (let y = 2; y <= this.height - 2; y++) {
            for (let x = Math.floor(this.neighborhood / 2); x <= this.width - Math.floor(this.neighborhood / 2); x++) {
                const state = this.checkCells(x, y);
                this.newCell(x, y, state);
            }
        }
    }

    getWorld() {
        return this.map.map((row, y) => row.map((cell, x) => {
            const bgColor = this.colors[cell];
            const fontColor = cell === 0 ? '#FFFFFF' : '#ffffff';
            return [cell, cell, { bgColor, fontColor }];
        }));
    }
}

function countBlueCells(world) {
    let blueCount = 0;
    world.forEach(row => {
        row.forEach(cell => {
            if (cell[2].bgColor === '#0000FF') {
                blueCount++;
            }
        });
    });
    return blueCount;
}

function checkDiversity(world) {
    const blueCount = countBlueCells(world);
    const requiredBlueCells = 15;

    return blueCount >= requiredBlueCells;
}

function postProcessVoronoi(world, width, height) {
    const startIndex = Math.floor(Math.random() * large.length);

    const sites = [];
    for (let i = 0; i < 54; i++) {
        sites.push({ x: Math.random() * width, y: Math.random() * height });
    }

    const voronoiDiagram = voronoi().extent([[0, 0], [width, height]]).x(d => d.x).y(d => d.y);
    const diagram = voronoiDiagram(sites);

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            const site = diagram.find(x, y);
            if (site) {
                const colorIndex = (sites.indexOf(site.data) + startIndex) % large.length;
                const color = large[colorIndex];
                if (world[y][x][2].bgColor === '#0000FF') {
                    world[y][x][2].bgColor = color;
                    world[y][x][2].blueVoronoi = true;
                    world[y][x][2].symbol = ' ';
                } else if (world[y][x][2].bgColor === '#000000') {
                    world[y][x][2].bgColor = '#333333';
                    world[y][x][2].blackVoronoi = true;
                }
                if(!world[y][x][2].bgColor) {
                    world[y][x][2].bgColor = '#000000';
                }
            }

        }
    }

    return world;
}

export default voronoy;
