import React, { useEffect } from 'react';
import { useDisplay } from '../../../context/DisplayContext';
import {clearWalletContainer} from '../utils/Utils';

const WalletTab = () => {
    const { display, containerCoords, walletTabOpen, activeTab, isMore, consoleTabOpen } = useDisplay();

    const blank = '%b{#000}%c{#000}--------------------------------'
    const line = '%c{#FFFFFF}∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗'

    useEffect(() => {
        if (!display || !containerCoords || containerCoords.length === 0) return;

        clearWalletContainer(display, containerCoords[0], walletTabOpen);

        if (!walletTabOpen) {
            if(!isMore) {
                display.drawText(containerCoords[0].startX + 2, containerCoords[0].endY - 3, line);
            } else {
                display.drawText(containerCoords[0].startX + 2, containerCoords[0].endY - 3, '%b{#000}%c{#FFFFFF}∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗ %b{#141F60}<↓>%b{} MORE');
            }
            display.drawText(containerCoords[0].startX + 4, containerCoords[0].endY - 1, '%b{#141F60}%c{#FFFFFF}<i>%b{} %c{#87AAD0}INVENTORY');
        } else {
            if(!isMore) {
                display.drawText(containerCoords[0].startX + 2, containerCoords[0].endY - 7, line);
            } else {
                display.drawText(containerCoords[0].startX + 2, containerCoords[0].endY - 7, '%b{#000}%c{#FFFFFF}∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗ %b{#141F60}<↓>%b{} MORE');
            }
            display.drawText(containerCoords[0].startX + 4, containerCoords[0].endY - 5, '%c{#3D3D3D}INVENTORY/');

            display.drawText(containerCoords[0].startX + 4, containerCoords[0].endY - 3, '%b{#141F60}%c{#FFFFFF}<a>%b{} %c{#87AAD0}SEKHMET\'S RING');

            display.drawText(containerCoords[0].startX + 2, containerCoords[0].endY - 1, '%b{#000}%c{#FFFFFF}∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗∗ %b{#141F60}<x>%b{} CLOSE');
        }
    }, [display, walletTabOpen, containerCoords, activeTab, consoleTabOpen, isMore]);

    return null;
};

export default WalletTab;