const clearContainer = (display, containerCoords, offset = 0, bg = '#000') => {
    const blank = '%b{'+bg+'}%c{'+bg+'}----------------------------------'

    const contentArea = containerCoords;
    const startX = contentArea.startX + 1;
    const startY = contentArea.startY;
    const endY = contentArea.endY - offset;

    for (let y = startY; y <= endY; y++) {
        display.drawText(startX, y, blank);
    }
}

const clearWalletContainer = (display, containerCoords, isWalletTabOpen) => {
    const blank = '%b{#000}%c{#000}----------------------------------'

    const contentArea = containerCoords;
    const startX = contentArea.startX + 1;

    let startY
    if(!isWalletTabOpen) {
        startY = contentArea.endY - 3;
    } else {
        startY = contentArea.endY - 9;
    }
    
    const endY = contentArea.endY;

    for (let y = startY; y <= endY; y++) {
        display.drawText(startX, y, blank);
    }

}

export { clearContainer, clearWalletContainer };
