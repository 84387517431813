const drawTerminal = (display, dimensions, consoleTabOpen) => {
  if (!display || !dimensions) return [];

  const { widthInTiles, heightInTiles } = dimensions;
  const headerHeight = 4;
  const menuContainerHeight = 14;
  const consoleContainerHeight = consoleTabOpen ? 14 : 3; // open not open

  const colors = ['#000', '#FFFFFF'];

  let containerCoords = [];
  let startX = widthInTiles < 63 ? Math.max(0, Math.floor((widthInTiles - 36) / 2)) : widthInTiles - 36 - 1;

  // Menu
  const startY3 = heightInTiles - menuContainerHeight - 3; 
  const endY3 = heightInTiles - 1;
  containerCoords.push({
      startX,
      startY: startY3,
      endX: startX + 35,
      endY: endY3,
      color: colors[0],
      border: colors[1]
  });

  // Console
  const startY2 = startY3 - consoleContainerHeight; // Position based on consoleTabOpen state
  const endY2 = startY3 - 1;
  containerCoords.push({
      startX,
      startY: startY2,
      endX: startX + 35,
      endY: endY2,
      color: colors[1],
      border: colors[0]
  });

  // Content - auto adapt
  const startY1 = headerHeight;
  const endY1 = startY2 - 1;
  containerCoords.push({
      startX,
      startY: startY1,
      endX: startX + 35,
      endY: endY1,
      color: colors[0],
      border: colors[1]
  });

  containerCoords.reverse().forEach(container => {
      drawContainer(display, container, dimensions);
  });

  return containerCoords;
};

const drawContainer = (display, { startX, startY, endX, endY, color, border }, dimensions) => {
  for (let y = startY; y <= endY; y++) {
      for (let x = startX; x <= endX; x++) {
          display.draw(x, y, ' ', null, color);
          // if (x === startX || (dimensions.widthInTiles < 63 && x === startX + 35)) {
          if (x === startX || (x === startX + 35)) {
            display.drawText(x, y, `%b{${color}}%c{${border}}║`);
          }
      }
  }
};

export default drawTerminal;
