const drawHeader = (display, dimensions, gen) => {
    if (!display || !dimensions) return;

    console.log(gen)

    let startX = 0;

    if (dimensions.widthInTiles < 63) {
        startX = Math.max(0, Math.floor((dimensions.widthInTiles - 36) / 2));
    } else {
        startX = dimensions.widthInTiles - 36 - 1;
        // display.drawText(startX, 0, '%c{#FFFFFF}║          G:/OBELISK_OS/           ');
        // display.drawText(startX, 1, '%c{#FFFFFF}║                                   ');
        // display.drawText(startX, 2, '%c{#FFFFFF}╟═══════════════════════════════════');    
    }

    display.drawText(startX, 0, '%c{#FFFFFF}╔══════════════════════════════════╗');
    display.drawText(startX, 1, '%c{#FFFFFF}║        G:/HEROGLYPHS_OS/         ║');
    display.drawText(startX, 2, '%c{#FFFFFF}║                                  ║');
    display.drawText(startX, 3, '%c{#FFFFFF}╟══════════════════════════════════╢');    

};

export default drawHeader;
