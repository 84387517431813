import { createNoise3D } from 'simplex-noise';
import { gradient } from '../../utils/Colors.js';
import { lerpColor, rgbToHex } from '../../utils/Utils.js';

export const noise3d = {
  name: 'Khamsin',
  generate: (width, height, numStates) => {
    const noise = createNoise3D();
    const noiseZ = Math.random() * 100;

    const world = [];
    const visited = Array.from({ length: height }, () => Array(width).fill(false));

    for (let y = 0; y < height; y++) {
      const row = [];
      for (let x = 0; x < width; x++) {
        const value = noise(x / 100, y / 100, noiseZ);

        const normalizedValue = (value + 1) / 2;

        const elevation = Math.round(normalizedValue * 9);
        const stateValue = Math.floor((value + 1) * 10 * numStates);

        const roundedNormalizedValue = Math.round(normalizedValue * 1000) / 1000;

        const color = lerpColor([1, 1, 1], [69, 69, 69], roundedNormalizedValue);
        const hexColor = rgbToHex(color[0], color[1], color[2]);

        let fontColor = '#FFFFFF';
        if (roundedNormalizedValue < 0.2) {
          fontColor = '#000000';
        } else if (roundedNormalizedValue >= 0.2 && roundedNormalizedValue <= 0.24) {
          const t = (roundedNormalizedValue - 0.2) / 0.1;
          const color = lerpColor([0, 0, 0], [255, 255, 255], t);
          fontColor = rgbToHex(color[0], color[1], color[2]);
        }

        const cell = [roundedNormalizedValue, stateValue, { noise: true, bgColor: hexColor, fontColor, elevation: roundedNormalizedValue }];
        row.push(cell);
      }
      world.push(row);
    }

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        if (!visited[y][x] && world[y][x][0] >= 0.9 && world[y][x][0] <= 1) {
          floodFill(world, x, y, width, height, visited, gradient, x, y);
        }
      }
    }

    markEdges(world, width, height);

    return world;
  },
};

function floodFill(world, x, y, width, height, visited, gradient, xStart, yStart) {
  let baseIndex = Math.floor(Math.random() * gradient.length);

  let queue = [{ x, y, index: baseIndex }];

  while (queue.length > 0) {
    let { x, y, index } = queue.shift();

    if (x < 0 || x >= width || y < 0 || y >= height || visited[y][x] || world[y][x][0] < 0.85 || world[y][x][0] > 1) continue;
    visited[y][x] = true;

    const gradientColor = gradient[index];
    world[y][x][2].fontColor = gradientColor;

    let nextIndex = (index + 1) % gradient.length;

    enqueueIfValid(queue, x + 1, y, width, height, visited, nextIndex);
    enqueueIfValid(queue, x - 1, y, width, height, visited, nextIndex);
    enqueueIfValid(queue, x, y + 1, width, height, visited, nextIndex);
    enqueueIfValid(queue, x, y - 1, width, height, visited, nextIndex);
  }

  markEdgeCells(world, xStart, yStart, width, height);
}

function markEdgeCells(world, xStart, yStart, width, height) {
  const stack = [{ x: xStart, y: yStart }];
  const visited = new Set();

  while (stack.length > 0) {
    const { x, y } = stack.pop();
    const key = `${x},${y}`;

    if (visited.has(key)) continue;
    visited.add(key);

    const neighbors = [
      { x: x + 1, y },
      { x: x - 1, y },
      { x, y: y + 1 },
      { x, y: y - 1 },
    ];

    for (const { x: nx, y: ny } of neighbors) {
      if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
        if (world[ny][nx][2].fontColor === '#FFFFFF') {
          world[y][x][2].edge = 1;
          break;
        }
        stack.push({ x: nx, y: ny });
      }
    }
  }
}

function markEdges(world, width, height) {
  const redEdges = [];

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (world[y][x][2].edge === 1) {
        redEdges.push({ x, y });
        const [r, g, b] = hexToRgb(world[y][x][2].fontColor);
        const [wr, wg, wb] = [255, 255, 255];
        const lerpedColor = lerpColor([r, g, b], [wr, wg, wb], 0.75);
        world[y][x][2].fontColor = rgbToHex(lerpedColor[0], lerpedColor[1], lerpedColor[2]);    
      }
    }
  }

  redEdges.forEach(({ x, y }) => {
    const neighbors = [
      { x: x + 1, y },
      { x: x - 1, y },
      { x, y: y + 1 },
      { x, y: y - 1 },
    ];

    for (const { x: nx, y: ny } of neighbors) {
      if (nx >= 0 && nx < width && ny >= 0 && ny < height && world[ny][nx][2].fontColor !== '#FFFFFF') {
        if (!world[ny][nx][2].edge) {
          world[ny][nx][2].edge = 2;
          
        }
      }
    }
  });

  const blueEdges = [];
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (world[y][x][2].edge === 2) {
        blueEdges.push({ x, y });
      }
    }
  }

  blueEdges.forEach(({ x, y }) => {
    const cell = world[y][x];
    const [r, g, b] = hexToRgb(cell[2].fontColor);
    const [wr, wg, wb] = [255, 255, 255];
    const lerpedColor = lerpColor([r, g, b], [wr, wg, wb], 0.50);
    cell[2].fontColor = rgbToHex(lerpedColor[0], lerpedColor[1], lerpedColor[2]);
  });

  const greenEdges = [];
  blueEdges.forEach(({ x, y }) => {
    const neighbors = [
      { x: x + 1, y },
      { x: x - 1, y },
      { x, y: y + 1 },
      { x, y: y - 1 },
    ];

    for (const { x: nx, y: ny } of neighbors) {
      if (
        nx >= 0 && nx < width && ny >= 0 && ny < height &&
        world[ny][nx][2].fontColor !== '#FFFFFF' &&
        world[ny][nx][2].edge !== 1 &&
        world[ny][nx][2].edge !== 2
      ) {
        if (!world[ny][nx][2].edge) {
          world[ny][nx][2].edge = 3;
          greenEdges.push({ x: nx, y: ny });
        }
      }
    }
  });

  greenEdges.forEach(({ x, y }) => {
    const cell = world[y][x];
    const [r, g, b] = hexToRgb(cell[2].fontColor);
    const [wr, wg, wb] = [255, 255, 255];
    const lerpedColor = lerpColor([r, g, b], [wr, wg, wb], 0.25);
    cell[2].fontColor = rgbToHex(lerpedColor[0], lerpedColor[1], lerpedColor[2]);
  });
}

function enqueueIfValid(queue, x, y, width, height, visited, index) {
  if (x >= 0 && x < width && y >= 0 && y < height && !visited[y][x]) {
    queue.push({ x, y, index });
  }
}

function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}
