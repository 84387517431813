export const transition = (newWorld, styleConf, display, onComplete) => {
  if (!styleConf || !display || typeof display.draw !== 'function') {
    return;
  }

  const totalRows = newWorld.length;
  const cellsToSkip = 37;

  const drawRow = (rowIndex) => {
    const row = newWorld[rowIndex];
    row.forEach((cellData, x) => {
      if (x >= row.length - cellsToSkip) return;

      const [elevation, value, data] = cellData;
      let { symbol = ' ', fontColor, bgColor } = data;

      if (data.symbol) {
        symbol = data.symbol;
      } else {
        try {
          const style = styleConf.getStyle(value);
          symbol = style.symbol;
        } catch (error) {
          console.error("Error getting style for value:", value, error);
          symbol = ' ';
        }
      }

      display.draw(x, rowIndex, symbol, fontColor, bgColor);
    });
  };

  const drawRowsInReverse = (index) => {
    if (index < 0) {
      onComplete();
      return;
    }
    drawRow(index);
    setTimeout(() => drawRowsInReverse(index - 1), 30);
  };

  drawRowsInReverse(totalRows - 1);
};
